import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

@Component({
    components: {
        ValidationProvider,
    },
})
export class HasProperyPart extends Vue {
    toggleArrayPropertyValue(property: string[] | null, value: string) {
        if (property == null) {
            property = [value];
            return;
        }

        const index = property.findIndex((option: string) => option === value);

        if (index === -1) {
            property.push(value);
        } else {
            property.splice(index, 1);
        }

        return property;
    }
}
