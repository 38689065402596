
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartEquimentSecurity extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyEquimentSecurity;

    localValue: PropertyEquimentSecurity = {
        alarm_system: null,
        intercom: null,
        videophone: null,
        smoke_detectors: null,
        camera_surveillance: null,
        gated_access: null,
        fire_detector: null,
    };

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyEquimentSecurity) {
        this.localValue = newValue;
    }
}
